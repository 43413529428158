<template>
  <div class="">
    <div class="vx-row placeholder-opacity-100 placeholder-black::placeholder">
      <div
        :class="`vx-col w-full md:w-1/${columns === undefined ? 3 : columns}`"
        v-for="mod in value.template.filter(field => field.input !== 'localized')"
        :key="mod.key"
      >
        <label-form :info="itemName(mod.subtitle)">{{
          itemName(mod.title, mod.required)
        }}</label-form>

        <vs-input
          class="w-full"
          v-if="mod.input === 'textfield'"
          :disabled="isReadOnly"
          :value="hasValue(mod.key) ? value.values[mod.key] : ''"
          @input="update($event, mod.key, mod.input)"
          :danger="checkForError(mod.key, mod.required)"
          danger-text="Invalid answer"
        />

        <vs-select :disabled="isReadOnly" v-if="mod.input === 'select'" class="w-full" :value="hasValue(mod.key) ? value.values[mod.key] : ''" @input="update($event, mod.key, mod.input)"
          :danger="checkForError(mod.key, mod.required)"
          :class="[!value.values[mod.key] && mod.required === true ? 'border-red' : 'border-red']"
          danger-text="Invalid answer">
              <vs-select-item
                :key="item"
                :value="item"
                :text="item"
                v-for="item in mod.values"
              />
        </vs-select>

        <div v-if="mod.input === 'checkbox'">
          <vs-checkbox
            :disabled="isReadOnly"
            :value="hasValue(mod.key) ? value.values[mod.key] : ''"
            @input="update($event, mod.key, mod.input)"
          >
            {{ itemName(mod.subtitle) }}
          </vs-checkbox>
          <span
            v-if="checkForError(value.values[mod.key], mod.required)"
            class="con-text-validation span-text-validation-danger"
            >Required</span
          >
        </div>

        <input-color
          :disabled="isReadOnly"
          v-if="mod.input === 'color'"
          :color="(value.values[mod.key]==undefined) ? '#00000000' : value.values[mod.key]"
          :required="mod.required"
          @input="update($event, mod.key, mod.input)"
        />

        <vs-input
          :disabled="isReadOnly"
          v-if="mod.input === 'number'"
          class="w-full border-grey-light"
          type="number"
          min="0"
          :value="hasValue(mod.key) ? value.values[mod.key] : ''"
          @input="update($event, mod.key, mod.input)"
          :danger="checkForError(mod.key, mod.required)"
          danger-text="Invalid answer"
        />

      </div>
      <div
          class="vx-col w-full"
          v-if="localizedFields.template.length > 0"
      >
          <LocalizedContent
              :fields-definition="localizedFields.template"
              :languages="languages"
              :fixed-languages="true"
              :value="localizedFields.values"
              @input="handleLocalizedInput"
              :columns="columns || 2"
          />
      </div>
    </div>
  </div>
</template>

<script>
import LabelForm from "@/modules/Shared/Components/form/LabelForm.vue";
import InputColor from "@/modules/Shared/Components/form/InputColor.vue";
import LocalizedContent from "@/modules/Shared/Components/form/LocalizedContent.vue";
export default {
  components: {
    LocalizedContent,
    LabelForm,
    InputColor,
  },
  props: {
    "value": Object,
    "columns": Number,
    languages: {
        type: Array,
        default: () => {
            return ['en', 'es', 'fr', 'ca']
        }
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
      localizedFields() {
          const fields = this.value.template
              .filter(field => field.input === 'localized')

          const values = this.value.values || {}

          return {
              template: fields.map(field => ({
                  name: field.key,
                  label: this.$t(field.title),
                  type: 'text',
              })),
              values: this.languages.map(lang => {
                  const objectLang =  {language: lang}

                  fields
                      .map(field => field.key)
                      .forEach(key => {
                        objectLang[key] = values[key] ? values[key][lang] : ''
                      })

                  return objectLang
              })
          }
      }
  },
  methods: {
    handleLocalizedInput(value) {
        this.value.values = this.value.values || {}
        value.forEach(localizedObject => {
            Object.keys(localizedObject)
                .filter(key => key !== 'language')
                .forEach(key => {
                    this.value.values[key] = this.value.values[key] || {}
                    this.value.values[key][localizedObject.language] = localizedObject[key]
                })
        })
    },
    getUserLanguage() {
      return this.$store.state.AppActiveUser.language
    },
    update(value, key, type) {
      let realValue = value
      if (type === "number" ) {
        realValue = isNaN(parseInt(realValue)) ? null : parseInt(realValue)
      }

      let updatedForm = {
        template: this.value.template,
        values: { ...this.value.values, [key]: realValue },
      }

      this.$emit("input", updatedForm);
      this.checkAllErrors()
    },
    hasValue(key) {
      if (!this.value) {
        return false
      }

      return !(this.value[key] === null || this.value[key] === undefined || this.value[key] === "");
    },
    itemName(name, required = false)
    {
      if (typeof name === 'string') {
        return this.$t(name) + (required ? ' *' : '')
      }

      return name[this.getUserLanguage()] + (required ? ' *' : '');
    },
    checkForError(key, required) {
      return !this.hasValue(key) && required;
    },
    checkAllErrors() {
      let errors = 0;
      this.value.template.forEach(element => {
        if(element.required) {
          if(element.input === 'color' && !(/^#[A-Fa-f0-9]{8}$/.test(this.value.values[element.key]))) {
            errors++;
          }
          else {
            if(!this.hasValue(element.key)) {errors++;}
          }
        }
      });
      if(errors == 0) this.$emit("check-validation",true);
      else this.$emit("check-validation",false);
    }
  },
  updated() {
    this.checkAllErrors();
  },
  created() {
    this.checkAllErrors();
  }
};
</script>
